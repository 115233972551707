<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 자재명 -->
          <c-text
            label="LBL0003254"
            name="materialName"
            v-model="searchParam.materialName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="MATERIAL_KIND_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="materialKindCd"
            label="자재구분"
            v-model="searchParam.materialKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-material-type
            type="search"
            label="자재분류"
            name="materialTypeCd"
            v-model="searchParam.materialTypeCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="materialTable"
      title="자재 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :columnSetting="false"
      :isExcelDown="false"
      :isFullScreen="false"
      rowKey="materialCd"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'material-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'single',
        plantCd: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    reviewCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'upMaterialTypeName',
            field: 'upMaterialTypeName',
            label: '자재 대분류',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'materialTypeName',
            field: 'materialTypeName',
            label: '자재 중분류',
            style: 'width:140px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'materialNo',
            field: 'materialNo',
            label: '자재관리번호',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '100%'
      },
      searchParam: {
        materialName: '',
        useFlag: 'Y',
        chemFlag: 'N',
        reviewCheck: '',
        materialTypeCd: '',
        materialKindCd: null,
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.mam.material.list.url;
      this.searchParam.plantCd = this.popupParam.plantCd;
      if (this.reviewCheck) this.searchParam.reviewCheck = 'check'
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['materialTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '자재를 선택하세요.', // 자재를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
